/* Provide sufficient contrast against white background */
a {
    color: #006B86;
}

    a:hover {
        text-decoration: none;
    }

code {
    color: #E01A76;
}

.btn-primary {
    color: #fff;
    background-color: #006B86;
}

.cmn-mdl {
    width: 400px;
    text-align: center;
}

.btn-primary:hover {
    background-color: #253746;
}

.cmn-dialogtext {
    font-size: 14px !important;
    text-align: center;
}

.cmn-dialogtitle {
    font-weight: 700;
    font-size: 16px;
    background-color: #F2F2F2;
}

.bold-text {
    font-weight: 700;
}

.cmn-secbtn {
    color: #006B86;
    font-weight: bold;
}

.MuiTablePagination-select {
    margin-top: 5px;
    line-height: 17px;
}

.MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel {
    margin-top: 1rem !important;
}

.MuiBadge-overlapCircular {
    top: 10% !important;
    right: 5% !important;
}

#securitiesTable {
    min-width: inherit;
    font-weight: 600;
    font-size: 14px;
}

    #securitiesTable thead th span:hover {
        color: rgba(0, 0, 0, 0.87)
    }

    #securitiesTable thead tr th {
        font-family: 'Montserrat', sans-serif;
        font-weight: 600;
        background: #D9D9D9;
    }

    #securitiesTable thead th span svg {
        color: #909090;
    }



    #securitiesTable thead tr th.filterInput {
        background: #fff;
        border: 1px solid #A1A7AF;
        color: #A1A7AF;
    }

    #securitiesTable .MuiInput-underline {
        height: 42px;
    }

.form-control:focus {
    -webkit-box-shadow: 0 1px 0 0 #26a69a;
    box-shadow: 0 1px 0 0 #26a69a;
}

#simple-tabpanel-0 .css-19kzrtu,
#simple-tabpanel-1 .css-19kzrtu,
#simple-tabpanel-2 .css-19kzrtu,
#simple-tabpanel-3 .css-19kzrtu,
#simple-tabpanel-4 .css-19kzrtu,
#simple-tabpanel-5 .MuiBox-root {
    padding-top: 0;
}

#simple-tabpanel-0 .MuiPaper-elevation1.MuiCard-root,
#simple-tabpanel-1 .MuiPaper-elevation1.MuiCard-root,
#simple-tabpanel-2 .MuiPaper-elevation1.MuiCard-root,
#simple-tabpanel-3 .MuiPaper-elevation1.MuiCard-root,
#simple-tabpanel-4 .MuiPaper-elevation1.MuiCard-root,
#simple-tabpanel-5 .MuiPaper-elevation1.MuiCard-root {
    border: 1px solid #087C9B;
    border-top-left-radius: 0;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

#simple-tabpanel-0 .MuiGrid-container.MuiGrid-spacing-xs-2, #simple-tabpanel-4 .MuiGrid-container.MuiGrid-spacing-xs-2, #simple-tabpanel-5 .MuiGrid-container.MuiGrid-spacing-xs-2 {
    margin-left: -15px;
    border-top-left-radius: 0;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

#simple-tabpanel-0 .MuiPaper-rounded.MuiPaper-elevation8.MuiCard-root, #simple-tabpanel-3 .MuiPaper-rounded.MuiPaper-elevation8.MuiCard-root, #simple-tabpanel-4 .MuiPaper-rounded.MuiPaper-elevation8.MuiCard-root, #simple-tabpanel-5 .MuiPaper-rounded.MuiPaper-elevation1.MuiCard-root, #simple-tabpanel-5 .MuiPaper-rounded.MuiPaper-elevation8.MuiCard-root {
    border: 1px solid #087C9B;
    border-top-left-radius: 0;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.commonBackgroundCard .Mui-selected {
    border: 1px solid #087C9B !important;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
}

#historyAndClaims {
    border: 1px solid #087C9B;
    border-top-left-radius: 0;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 16px;
}

    #historyAndClaims:hover {
        transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 5px -3px, rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgba(0, 0, 0, 0.12) 0px 3px 14px 2px;
    }

.commonBackgroundCard .css-hs2rlb-MuiTabs-indicator {
    display: none;
}

.button:focus, button:focus, button span:focus-visible, button svg:focus-visible, button svg:focus {
    outline: none !important;
    background: none !important;
}

.css-fw1jji-MuiFormLabel-root-MuiInputLabel-root, .css-5qz37o-MuiFormControlLabel-root .MuiFormControlLabel-label {
    -webkit-transform: translate(0, -1.5px) scale(0.75);
    -moz-transform: translate(0, -1.5px) scale(0.75);
    -ms-transform: translate(0, -1.5px) scale(0.75);
    transform: translate(0, -1.5px) scale(0.75);
    color: rgba(0, 0, 0, 0.38);
}

#commonOuterWraper .MuiAccordionSummary-root {
    position: absolute;
    top: -25px;
    right: 10px;
    padding: 0px;
    min-height:auto;
}

#commonOuterWraper .MuiAccordionSummary-root:before {
    content: 'View more details...';
}
#commonOuterWraper .MuiAccordionSummary-root.Mui-expanded {
        top: -25px;
        right: 10px;
        padding: 0px;
        min-height:auto;
        
}
#commonOuterWraper .MuiAccordionSummary-content.Mui-expanded {
    margin: 0px;
}
#commonOuterWraper .MuiAccordionSummary-root.Mui-expanded:before {
    content: 'View less details...';
}
#commonOuterWraper MuiAccordionSummary-content.Mui-expanded {
    margin: 0px;
}

#commonOuterWraper {
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    padding: 16px 16px 30px 16px;
    margin-bottom: 31px;
}

    #commonOuterWraper .MuiCard-root {
        outline: none !important;
        box-shadow: 0px 4px 4px 0px rgba(0,0,0,0.25);
        border: 0.5px solid #D9D9D9;
        padding: 20px 16px 0px 16px;
    }

    #commonOuterWraper .MuiCardContent-root {
        padding: 0 !important;
    }

    #commonOuterWraper .MuiInputLabel-root {
        color: #000 !important;
        letter-spacing: 0.15px;
        -webkit-text-fill-color: #000 !important;
        font-family: 'Roboto',sans-serif !important;
        font-size: 16px !important;
        transform: translate(0px, -1.5px) scale(0.75);
    }

    #commonOuterWraper .Mui-disabled {
        color: #fff;
        letter-spacing: 0.15px;
        -webkit-text-fill-color: #838A95;
        font-family: 'Montserrat',sans-serif;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 0px;
        height: 32px;
        padding-left: 5px;
        padding-right: 5px;
        border-radius: 0px; margin-left:0px;
    }
.MuiOutlinedInput-notchedOutline {
    border-left: none;
    border-right: none;
    border-top: none;
    border-top: none;
    border-bottom-style: dotted;
    border-color: rgba(0, 0, 0, 0.42) !important;
}
#currencyType {padding-left:0px !important; padding-right:0px !important;}
.MuiFormControl-root.MuiTextField-root.css-1hlrvm2-MuiFormControl-root-MuiTextField-root {
    margin-left: 0;
}
#commonOuterWraper .MuiGrid-root {
    margin-bottom: 20px;
}

    #commonOuterWraper .MuiFormGroup-root {
        align-items: end;
    }

    #commonOuterWraper .MuiTypography-root {
        color: #006B86;
        font-family: 'Roboto',sans-serif;
    }

    #commonOuterWraper .PrivateSwitchBase-root {
        color: #006B86;
        height: 23px;
    }

    #commonOuterWraper .Mui-disabled .PrivateSwitchBase-root {
        color: #838A95;
        padding-top: 0px;
    }

    #commonOuterWraper .MuiAccordion-root {
        box-shadow: none;
    }

    #commonOuterWraper textarea {
        text-overflow: ellipsis;
        display: -webkit-box !important;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        height: 26px !important;
    }

    #commonOuterWraper .MuiAccordionDetails-root {
        padding: 0;
    }
#panel1a-content .MuiOutlinedInput-notchedOutline {border-top:none; border-left:none; border-right:none; border-bottom-style:dotted; border-radius:0px;}
#divRejectReason .rejectReasonHelper label {color:#000;}
#divRejectReason .rejectReasonHelper .Mui-disabled {color: rgba(0, 0, 0, 0.38);}